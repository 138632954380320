import React, { useEffect } from "react";
import ArticleCard from "./components/ArticleCard";
import { useArticles } from "../../utils";

const Articles = () => {
  const { getArticles, articles } = useArticles();
  useEffect(() => {
    getArticles();
  }, []);

  return (
    <div className="py-10 body-width">
      <h2 className="text-4xl font-semibold text-center" data-aos="zoom-out">
        Articles
      </h2>

      <section
        className="mt-8 body-width grid gap-6"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        }}
        // data-aos="fade-up"
      >
        {articles.map((article, index) => (
          <ArticleCard
            data={article}
            key={article.id}
            animationDelay={index * 100}
          />
        ))}
      </section>
    </div>
  );
};

export default Articles;
