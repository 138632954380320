import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import heroImg from '../../assets/images/article-detail-hero.png';
import instagram from '../../assets/icons/instagram.svg';
import linkedIn from '../../assets/icons/linkedin.svg';
import tiktok from '../../assets/icons/tiktok.svg';
import twitter from '../../assets/icons/twitter.svg';

const ArticleDetails = () => {
	const { id: articleId } = useParams();
	const navigate = useNavigate();

	if (!articleId) return navigate(-1);

	return (
		<div
			style={{ minHeight: 'calc(100vh - 90px)' }}
			className="bg-white pt-4 lg:pt-8 pb-5"
		>
			<header className='mx-4' data-aos="zoom-out">
				<div
					className="rounded-xl overflow-hidden bg-primary flex flex-col lg:flex-row max-w-[1440px] lg:mx-auto w-full"
					style={{ paddingInline: 0 }}
				>
					<div className="flex-1">
						<img
							src={heroImg}
							alt="hero header"
							className="w-full h-full object-cover max-h-[200px] lg:max-h-none"
						/>
					</div>
					<div className="flex flex-1 flex-col justify-center p-4 lg:p-10">
						<h1 className="text-white text-xl lg:text-3xl font-bold">
							The Power of Giving back
						</h1>
						<h2 className="text-base lg:text-xl text-secondary font-semibold">
							How Volunteering Transforms Communities
						</h2>
					</div>
				</div>
			</header>

			<div className="body-width text-black mt-4 lg:mt-10" data-aos="fade-up">
				<p className="leading-[180%]">
					Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio cumque
					alias aspernatur ipsam commodi, distinctio architecto veniam debitis
					maiores totam at obcaecati quam aliquam sint expedita! Nam dolorem
					error quos. Sequi fuga, sunt impedit dolore ea odio beatae, maiores
					necessitatibus nam pariatur doloribus eos sapiente voluptatibus
					assumenda deserunt iste? Optio, nisi! Quo ipsam id incidunt similique
					quam tempora, repellendus excepturi distinctio eaque rerum ad
					accusantium explicabo. Laudantium, consequatur iure tenetur iste dolor
					minima quidem minus corrupti et, eum mollitia non aut dignissimos. Non
					nam doloremque necessitatibus iusto. Officia ducimus et debitis sint,
					quisquam alias neque impedit vero. Cupiditate fuga sed optio nobis!
					Dolor ut expedita pariatur animi, illum quia aspernatur. Repellat ipsa
					voluptatibus repudiandae impedit magni corrupti minus doloremque
					provident? Deleniti accusantium possimus provident officiis nobis,
					quasi aliquid consequuntur reprehenderit. Suscipit dolorum deleniti
					facilis beatae ducimus modi perspiciatis, tempore totam sed debitis
					laborum enim cupiditate nisi ut reprehenderit eveniet facere ab
					explicabo, quaerat, praesentium necessitatibus nihil veritatis? Quam
					dignissimos minus at alias enim dolore voluptatem corporis temporibus.
					Veniam placeat tenetur optio repellat totam vel, nam aut officia,
					neque labore recusandae eaque. A asperiores vel, accusamus, quo dolor
					nesciunt soluta adipisci, veritatis dignissimos voluptatum officiis
					deleniti nisi et quidem amet optio ullam. Fuga repellendus mollitia
					eos iure incidunt fugiat, voluptatum veniam vero enim in voluptas et
					similique rem non sed soluta quis officia. Aperiam blanditiis odio,
					quam delectus recusandae dolor rerum natus iusto consequuntur veniam
					doloremque esse dolorum magnam! Iste quod, non illo nihil neque
					voluptates, pariatur ipsam sed quas illum voluptatum commodi error
					fuga autem voluptatem similique nesciunt itaque deleniti. Harum,
					voluptas quas eaque facilis labore aperiam quod tempora. Quae sit
					dicta quisquam quibusdam expedita voluptatem incidunt odio, quaerat
					ab, totam dignissimos omnis sed. Rem sequi suscipit non ut facere
					adipisci molestiae architecto, quod, culpa, doloremque repudiandae at
					voluptatibus quos nemo qui accusamus dolorum alias maiores!
					Repudiandae aperiam alias rerum delectus nostrum quibusdam quod culpa
					ipsa dolorem ut corporis consequatur, et laudantium, veritatis
					repellat libero sit voluptatum a porro perspiciatis at. Reiciendis
					possimus, nulla, inventore voluptate nihil harum doloremque sit
					necessitatibus animi, consequatur laboriosam neque quaerat error
					accusantium ut. Sint reiciendis, doloremque consequatur nisi
					exercitationem ducimus consectetur quasi numquam alias obcaecati,
					eveniet commodi praesentium, quaerat expedita voluptatibus et velit
					odio eum quos dignissimos in hic. Adipisci dolore culpa vero
					cupiditate maiores repellendus mollitia dignissimos ea eaque aperiam
					praesentium, dicta hic corporis, odio temporibus rem ex minus expedita
					ipsum dolorem perspiciatis labore eius repellat. Eos ullam quas,
					tempora nihil accusamus quasi non! Dolore quaerat numquam, ipsam
					labore odit, veritatis inventore eaque ipsa cupiditate hic assumenda
					cum iste! Repellendus sit fuga veniam nesciunt dignissimos atque nam
					ullam nihil doloremque porro. Nostrum ex ad iure corporis minima saepe
					impedit aperiam similique iusto magnam. Dolorem dolore harum, itaque
					voluptates blanditiis ipsa magni nulla nisi sint quis perspiciatis,
					maxime aliquam sed minima soluta, magnam praesentium fugiat molestiae
					ex earum repellat aspernatur ipsum corporis? Velit dolore nulla saepe
					pariatur aliquid, tenetur enim rerum, architecto a veniam ad sapiente
					culpa vitae ut corporis dolorum! Maxime, laborum impedit?
				</p>
			</div>
			<footer className="mt-6 w-fit mx-auto bg-primary rounded-lg flex flex-wrap items-center gap-6">
				<p>Share on</p>
				<div className="flex gap-2 items-center">
					<a target="_blank" href="https://instagram.com" rel="noreferrer">
						<img
							src={instagram}
							alt="instagram"
							className="w-[20px] h-[20px]"
						/>
					</a>
					<a target="_blank" href="https://linkedin.com" rel="noreferrer">
						<img src={linkedIn} alt="linkedin" className="w-[20px] h-[20px]" />
					</a>
					<a target="_blank" href="https://tiktok.com" rel="noreferrer">
						<img src={tiktok} alt="tiktok" className="w-[20px] h-[20px]" />
					</a>
					<a target="_blank" href="https://twitter.com" rel="noreferrer">
						<img src={twitter} alt="twitter" className="w-[20px] h-[20px]" />
					</a>
				</div>
			</footer>
		</div>
	);
};

export default ArticleDetails;
