import React from "react";
import "../css/button.css";
import { useEvents } from "../utils";

export default function Button({
  onClick = () => {},
  primary = false,
  title = "Submit",
  aos,
  aosDuration,
}) {
  const { isLoading } = useEvents();
  return (
    <button
      data-aos={aos}
      data-aos-duration={aosDuration}
      className={`${
        isLoading ? "bg-gray-500" : !primary ? "bg-secondary" : "bg-primary"
      } button hover:opacity-85`}
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? "Loading" : title}
    </button>
  );
}
