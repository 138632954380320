import React, { useState } from "react";
import { FilePicker } from "../../components/FilePicker";
import { useAdmin } from "../../utils/hooks/Admin/useAdmin";

export const AddTestimony = () => {
  const [img, setImg] = useState(null);
  const { addTestimony, isLoading } = useAdmin();
  const handleSubmit = (e) => {
    e.preventDefault();
    const fullName = document.getElementById("fullName").value;
    const email = document.getElementById("email").value;
    const testimony = document.getElementById("testimony").value;
    addTestimony({
      fullName: fullName,
      email: email,
      testimony: testimony,
      date: new Date(),
      img: img,
    });
  };

  return (
    <div class="body-width flex flex-col gap-10 py-10">
      <div class="flex flex-col sm:flex-row align-center gap-10 p-10 ">
        <div class="w-[15Rem] h-[15Rem] sm:w-[30Rem] sm:h-[30Rem] flex justify-center place-items-center">
          <FilePicker setFile={setImg} img={img} />
        </div>
        <form
          class="flex flex-col w-[100%] max-w-sm mx-auto"
          onSubmit={handleSubmit}
        >
          <div class="mb-5">
            <label
              for="fullName"
              class="block mb-2 text-sm font-medium dark:text-white"
            >
              Full Name
            </label>
            <input
              type="text"
              id="fullName"
              class="bg-gray-50 border border-gray-300  text-sm rounded-lg text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Full Name"
              required
            />
          </div>
          <div class="mb-5">
            <label
              for="email"
              class="block w-[100%] mb-2 text-sm font-medium  dark:text-white"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@nadir.ovp"
              required
            />
          </div>
          <div class="mb-5">
            <label
              for="testimony"
              class="block mb-2 text-sm font-medium  dark:text-white"
            >
              Testimony
            </label>
            <textarea
              required
              type="text"
              id="testimony"
              class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <button
            type="submit"
            class="text-white bg-secondary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-secondary dark:hover:bg-secondary dark:focus:ring-blue-800"
            disabled={isLoading}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
