import React from "react";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { routes } from "../../api/routes";
import { toast } from "react-toastify";

export const AdminDashboard = () => {
  const navigate = useNavigate();

  const buttons = [
    {
      title: "Add Event",
      onpress: () => {
        navigate(routes.admin.addEvent);
      },
    },
    {
      title: "Add Testimony",
      onpress: () => {
        navigate(routes.admin.addTestimony);
      },
    },
    {
      title: "Manage Events",
      onpress: () => {
        toast("coming soon");
      },
    },
    {
      title: "Volunteers",
      onpress: () => {
        toast("coming soon");
      },
    },
    {
      title: "Add Blog",
      onpress: () => {
        toast("coming soon");
      },
    },
  ];

  return (
    <div class="body-width flex flex-col gap-10 py-10">
      <h1 class="text-xl font-bold text-center">Welcome Admin!</h1>
      <div class="flex flex-col sm:flex-row place-items-center gap-10 justify-center">
        {buttons.map((button, index) => (
          <Button
            aos="fade-left"
            aosDuration={"2000"}
            key={index}
            title={button.title}
            onClick={button.onpress}
          />
        ))}
      </div>
    </div>
  );
};
