import React, { useState } from "react";
import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import firebase from "../../api/firebase";

export const useTestimonies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const db = firebase.firestore();
  const [testimonies, setTestimonies] = useState([]);

  const getTestimonies = async () => {
    setIsLoading(true);
    try {
      const { docs } = await getDocs(collection(db, "Testimonies"));
      const allTestimonies = docs.map((doc) => {
        const { date, ...res } = doc.data();
        return { date: date.toDate(), id: doc.id, ...res };
      });
      setTestimonies(allTestimonies);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    testimonies,
    getTestimonies,
    setTestimonies,
    isLoading,
  };
};
