import React from 'react';
import platformImg from '../../../assets/images/platform-img.png';

const OurPlatform = () => {
	return (
		<section className="min-h-screen pb-20 bg-white text-black">
			<div className="body-width flex flex-col lg:flex-row gap-4 lg:gap-20">
				<div className="flex-[0.5]" data-aos="fade-down" data-aos-delay="300">
					<img
						src={platformImg}
						alt="Platform"
						className="lg:object-cover relative w-full h-full object-bottom max-w-[300px] lg:max-w-none lg:max-h-[750px] mx-auto lg:mx-0"
					/>
				</div>
				<div
					className="flex-1 flex flex-col pt-4 lg:py-[6rem]"
					data-aos="fade-left"
					data-aos-delay="300"
				>
					<h2 className="font-bold text-secondary text-center text-2xl lg:text-4xl underline leading-[200%]">
						Our Platform
					</h2>

					<div className="text-secondary font-semibold text-base lg:text-lg flex-1 flex flex-col gap-[4rem] lg:gap-[10rem] mt-4 lg:mt-16 leading-[150%]">
						<p>
							we offer a dynamic and inclusive space that transcends
							geographical constraints, providing a gateway to diverse and
							rewarding volunteering experiences.
						</p>
						<p>
							Our innovative approach empowers volunteers to make a tangible
							impact while offering organizations a streamlined way to access
							skilled contributors.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OurPlatform;
