import React, { useState } from "react";
import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import firebase from "../../api/firebase";

export const useArticles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const db = firebase.firestore();
  const [articles, setArticles] = useState([]);

  const getArticles = async () => {
    try {
      const { docs } = await getDocs(collection(db, "Articles"));
      const allArticles = docs.map((doc) => {
        const { date, ...res } = doc.data();
        return { date: date.toDate(), id: doc.id, ...res };
      });
      console.log(allArticles);
      setArticles(allArticles);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    articles,
    setArticles,
    getArticles,
    isLoading,
  };
};
