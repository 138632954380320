import React from 'react';

const BrandMeaning = () => {
	return (
		<section className="min-h-screen py-20 bg-white flex flex-col gap-8 justify-between">
			<h2
				data-aos="fade-down"
				className="text-2xl lg:text-4xl text-secondary font-semibold text-center body-width"
			>
				The meaning behind our name
			</h2>

			<div
				data-aos="fade-up"
				className="font-montserrat text-black body-width text-center"
			>
				<p className="font-bold text-3xl lg:text-6xl">NADIR</p>
				<p className="font-semibold text-xl lg:text-4xl text-secondary">
					/neɪdɪə/
				</p>
			</div>

			<div
				data-aos="zoom-out"
				className="font-montserrat text-black body-width text-center space-y-3"
			>
				<p className="text-2xl lg:text-4xl">Officially...</p>
				<p className="font-bold text-2xl lg:text-4xl">The lowest point</p>
				<p className="text-2xl lg:text-4xl">We see it as...</p>
				<p className="font-bold text-3xl lg:text-6xl">The starting point</p>
				<p className="text-xl">
					Join us to <span className="font-bold">give back</span> and{' '}
					<span className="font-bold">impact lives</span>
				</p>
			</div>
		</section>
	);
};

export default BrandMeaning;
