import React, { useState } from "react";
import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import firebase from "../../../api/firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useAdmin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const db = firebase.firestore();

  const addEvent = async (fdata) => {
    setIsLoading(true);
    try {
      const res = await addDoc(collection(db, "Events"), fdata);
      if (!res) return toast.error("some error occured");
      toast.success("Event added Successfully");
      navigate(-1);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addTestimony = async (fdata) => {
    setIsLoading(true);
    try {
      const res = await addDoc(collection(db, "Testimonies"), fdata);
      if (!res) return toast.error("some error occured");
      toast.success("Testimony Saved Successfully");
      navigate(-1);
      //   return res.id;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    addEvent,
    addTestimony,
    isLoading,
  };
};
