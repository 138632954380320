import React, { useEffect } from "react";
import "../css/Home.css";
import Button from "../components/Button";
import { useTestimonies } from "../utils/hooks/useTestimonies";

const Home = () => {
  const { getTestimonies, testimonies } = useTestimonies();
  useEffect(() => {
    getTestimonies();
  }, []);

  return (
    <div>
      <Landing />
      <Stats />
      <Reviews data={testimonies} />
      <Articles />
    </div>
  );
};

const Landing = () => {
  return (
    <div
      style={{ minHeight: "calc(100vh - 90px)" }}
      className="body-width flex flex-col gap-10 py-10"
    >
      <div>
        <img
          src={require("../assets/images/landing-image.png")}
          alt="Orphans"
          className="w-full h-full object-cover"
          data-aos="zoom-out"
          data-aos-duration="2000"
        />
        <div>
          <h2 class="font-semi-bold sm:font-bold text-xl sm:text-2xl text-center">
            Nadir: An <br />
            online volunteering platform{" "}
          </h2>
        </div>
      </div>

      {/* <div
        class="flex flex-1 bg-contain bg-center bg-no-repeat "
        style={{
          backgroundImage: `url(${require("../assets/images/landing-image.png")})`,
        }}
      > */}

      <div class="flex flex-col sm:flex-row align-center justify-center gap-4 sm:gap-8 p-1 ">
        <div
          class="text-center sm:text-left gap-2"
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <h2 class="font-bold text-2xl">
            Want to Donate or Share an Oppurtunity?
          </h2>
          <h2 class="font-semi-bold text-md">
            Learn how you can support those in your community through the
            various means highlighted?
          </h2>
        </div>
        <div data-aos="fade-left" data-aos-duration="2000">
          <Button title="Support" />
        </div>
      </div>
    </div>
  );
};

const Stats = () => {
  const stats = [
    {
      stat: "40.1%",
      message: "of Nigerians are living in poverty",
      source: "National Bureau of statistics",
    },
    {
      stat: "40.1%",
      message: "of Nigerians are living in poverty",
      source: "National Bureau of statistics",
    },
    {
      stat: "40.1%",
      message: "of Nigerians are living in poverty",
      source: "National Bureau of statistics",
    },
  ];
  return (
    <div className="hidden py-10  bg-white min-h-screen justify-center align-center flex-wrap gap-10">
      {stats.map((stat) => {
        return (
          <div
            className="flex flex-col p-10 justify-center rounded-full bg-[#243443] text-center h-52 w-52 sm:h-80 sm:w-80 "
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <p class="text-4xl font-bold text-[#59AEC2]">{stat.stat}</p>
            <p class="font-bold">{stat.message}</p>
            <p class="text-[#808891]">{stat.source}</p>
          </div>
        );
      })}
    </div>
  );
};

const Reviews = ({ data }) => {
  return (
    <div className="body-width py-10 flex flex-col justify-center align-center gap-10 min-h-screen ">
      <h2
        className="text-xl sm:text-3xl font-bold tracking-wide text-center"
        data-aos="zoom-out"
      >
        How our friends feel about Nadir
      </h2>

      <div class="flex flex-wrap justify-center gap-4 sm:gap-8 flex-1 bg-red content-center">
        {data.map(({ testimony, fullName, id }) => {
          return (
            <div
              key={id}
              class="bg-white text-black rounded p-10 w-full max-w-lg"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <div className="flex flex-row gap-4 align-center">
                <div class="w-20 h-20 bg-red-400">
                  <img
                    src={require("../assets/images/volunteer.jpeg")}
                    class="rounded-sm object-cover w-20 h-20"
                  />
                </div>
                <div>
                  <h2 class="font-bold">{fullName}</h2>
                  <h2>Volunteer</h2>
                </div>
              </div>
              <p class="mt-3">{testimony}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Articles = () => {
  return (
    <div className="flex flex-col bg-white min-h-screen py-10 align-center">
      <h1 className="font-bold text-4xl text-black ">Our Articles</h1>
    </div>
  );
};

export default Home;
