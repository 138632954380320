import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const ModalBackdrop = ({
	children,
	className = '',
	closeModal = () => {
		return;
	},
}) => {
	useEffect(() => {
		if (document) {
			document.body.style.overflow = 'hidden';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);

	return ReactDOM.createPortal(
		<div
			onClick={closeModal}
			className={`fixed inset-0 bg-[rgba(0,0,0,0.9)] grid place-items-center text-white ${className}`}
		>
			{children}
		</div>,
		document.getElementById('modal-root')
	);
};

export default ModalBackdrop;
