import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../api/routes";

function ArticleCard({ data, animationDelay }) {
  return (
    <Link to={`${routes.articles}/${data.id}`}>
      <article
        data-aos="zoom-in"
        data-aos-delay={animationDelay}
        className="flex rounded-lg h-full bg-white overflow-hidden"
      >
        <div className="flex-[0.3]">
          <img
            alt={data.title}
            src={require("../../../assets/images/article.png")}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="text-black text-xs flex-[0.7] p-4 max-h-[180px] overflow-y-hidden">
          <h2 className="font-semibold text-base">{data.title}</h2>
          <p className="mt-2 mb-1 text-gray-500 font-medium">{data.subtitle}</p>
          <p className="line-clamp-3">{data.content}</p>
        </div>
      </article>
    </Link>
  );
}

export default ArticleCard;
