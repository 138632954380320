import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import ModalBackdrop from '../assets/ModalBackdrop';

const OurGallery = () => {
	const [showEventDetails, setShowEventDetails] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);

	return (
		<div className="py-10 body-width">
			<h2 className="text-4xl font-semibold text-center" data-aos="zoom-out">
				Our Gallery
			</h2>

			<section className="mt-8" data-aos="fade-up">
				<ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
					<Masonry gutter={24}>
						{EVENTS.map((event) => (
							<div
								key={event.title}
								className="cursor-pointer overflow-hidden rounded-3xl"
								onClick={() => {
									setSelectedEvent(event);
									setShowEventDetails(true);
								}}
							>
								<img
									src={event.image}
									alt={event.title}
									className="hover:scale-95 transition-all"
								/>
							</div>
						))}
					</Masonry>
				</ResponsiveMasonry>
			</section>

			{showEventDetails && selectedEvent && (
				<ModalBackdrop closeModal={() => setShowEventDetails(false)}>
					<div className="rounded-2xl w-full max-w-5xl max-h-[650px] h-full flex flex-col">
						<h2
							className="text-3xl text-center font-semibold"
						>
							{selectedEvent.title}
						</h2>

						<section
							data-aos="fade-up"
							data-aos-duration="800"
							className="mt-8 flex flex-col lg:flex-row gap-6 lg:gap-[40px] h-full"
						>
							<div className="flex-[0.5] h-full overflow-hidden rounded-xl">
								<img
									src={selectedEvent.image}
									alt={selectedEvent.title}
									className="w-full h-full object-cover"
								/>
							</div>
							<div className="flex-[0.5] space-y-5">
								<div>
									<h3 className="text-lg font-semibold mb-1">Date</h3>
									<p>{selectedEvent.date.toLocaleDateString()}</p>
								</div>

								<div>
									<h3 className="text-lg font-semibold mb-1">
										Event: {selectedEvent.title}
									</h3>
									<p>{selectedEvent.description}</p>
								</div>

								<div>
									<h3 className="text-lg font-semibold mb-1">
										Collaborators and Sponsors
									</h3>
									<ul className="list-disc relative left-7">
										{selectedEvent.collaborators.map((collaborator) => (
											<li>{collaborator}</li>
										))}
									</ul>
								</div>
							</div>
						</section>
					</div>
				</ModalBackdrop>
			)}
		</div>
	);
};

export default OurGallery;

const EVENTS = [
	{
		image: require('../assets/images/Rectangle 15.png'),
		title: 'Book Drive - 1',
		date: new Date(),
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta iusto ab neque facilis labore eligendi autem accusamus repellendus. Eius illo voluptates hic accusantium harum voluptatum sequi aliquam optio eaque corrupti.',
		collaborators: [
			'Nile University',
			'Chess in slums',
			"Hamza's book store",
			'Unicef',
		],
	},
	{
		image: require('../assets/images/Rectangle 15.png'),
		title: 'Book Drive - 2',
		date: new Date(),
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta iusto ab neque facilis labore eligendi autem accusamus repellendus. Eius illo voluptates hic accusantium harum voluptatum sequi aliquam optio eaque corrupti.',
		collaborators: [
			'Nile University',
			'Chess in slums',
			"Hamza's book store",
			'Unicef',
		],
	},
	{
		image: require('../assets/images/Rectangle 16.png'),
		title: 'Book Drive - 3',
		date: new Date(),
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta iusto ab neque facilis labore eligendi autem accusamus repellendus. Eius illo voluptates hic accusantium harum voluptatum sequi aliquam optio eaque corrupti.',
		collaborators: [
			'Nile University',
			'Chess in slums',
			"Hamza's book store",
			'Unicef',
		],
	},
	{
		image: require('../assets/images/Rectangle 18.png'),
		title: 'Book Drive - 4',
		date: new Date(),
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta iusto ab neque facilis labore eligendi autem accusamus repellendus. Eius illo voluptates hic accusantium harum voluptatum sequi aliquam optio eaque corrupti.',
		collaborators: [
			'Nile University',
			'Chess in slums',
			"Hamza's book store",
			'Unicef',
		],
	},
	{
		image: require('../assets/images/Rectangle 19.png'),
		title: 'Book Drive - 5',
		date: new Date(),
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta iusto ab neque facilis labore eligendi autem accusamus repellendus. Eius illo voluptates hic accusantium harum voluptatum sequi aliquam optio eaque corrupti.',
		collaborators: [
			'Nile University',
			'Chess in slums',
			"Hamza's book store",
			'Unicef',
		],
	},
	{
		image: require('../assets/images/Rectangle 21.png'),
		title: 'Book Drive - 6',
		date: new Date(),
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta iusto ab neque facilis labore eligendi autem accusamus repellendus. Eius illo voluptates hic accusantium harum voluptatum sequi aliquam optio eaque corrupti.',
		collaborators: [
			'Nile University',
			'Chess in slums',
			"Hamza's book store",
			'Unicef',
		],
	},
	{
		image: require('../assets/images/Rectangle 15.png'),
		title: 'Book Drive - 7',
		date: new Date(),
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta iusto ab neque facilis labore eligendi autem accusamus repellendus. Eius illo voluptates hic accusantium harum voluptatum sequi aliquam optio eaque corrupti.',
		collaborators: [
			'Nile University',
			'Chess in slums',
			"Hamza's book store",
			'Unicef',
		],
	},
	{
		image: require('../assets/images/Rectangle 15.png'),
		title: 'Book Drive - 8',
		date: new Date(),
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta iusto ab neque facilis labore eligendi autem accusamus repellendus. Eius illo voluptates hic accusantium harum voluptatum sequi aliquam optio eaque corrupti.',
		collaborators: [
			'Nile University',
			'Chess in slums',
			"Hamza's book store",
			'Unicef',
		],
	},
];
