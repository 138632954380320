import React, { useState } from "react";
import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import firebase from "../../api/firebase";

export const useEvents = () => {
  const [isLoading, setIsLoading] = useState(false);
  const db = firebase.firestore();
  const [events, setEvents] = useState([]);
  const [value, setValue] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const getEvents = async () => {
    try {
      const { docs } = await getDocs(collection(db, "Events"));
      const allEvents = docs.map((doc) => {
        const { date, ...res } = doc.data();
        return { date: date.toDate(), id: doc.id, ...res };
      });
      setEvents(allEvents);
      onChange(value);
    } catch (error) {
      console.error(error);
    }
  };

  const rsvpForEvent = async (fdata) => {
    setIsLoading(true);
    if (!fdata.name || !fdata.email || !fdata.phone)
      return alert("Please fill all fields");
    try {
      const q = query(
        collection(db, "Volunteers"),
        where("email", "==", fdata.email)
      );
      const alreadyExist = await getDocs(q);
      if (!alreadyExist.empty) return alert("Email already in use");

      const res = await addDoc(collection(db, "Volunteers"), fdata);
      alert("Successfully registered");
      return res.id;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (val) => {
    setValue(val);
    setSelectedEvent(
      events.filter((event) => (isSameDay(event.date, val) ? event : null))[0]
    );
  };
  return {
    value,
    events,
    setEvents,
    getEvents,
    isSameDay,
    onChange,
    selectedEvent,
    rsvpForEvent,
    isLoading,
  };
};
