import React from 'react';
import childrenLaughingImg from '../../../assets/images/children-laughing-1.png';
import childrenReadingImg from '../../../assets/images/children-reading-2.png';

const WhyWeDoWhatWeDo = () => {
	return (
		<section className="min-h-screen py-20">
			<h2 className="text-2xl lg:text-4xl font-semibold text-secondary text-center">
				Why we do what we do
			</h2>

			<div className="flex flex-col lg:flex-row lg:items-end mt-10 gap-16">
				<div className="w-full max-w-[900px]" data-aos="fade-right">
					<img
						src={childrenLaughingImg}
						alt="children laughing"
						className="object-cover max-h-[450px]"
					/>
					<img
						src={childrenReadingImg}
						alt="children reading"
						className="object-cover max-h-[450px] -mt-[10rem] ml-auto"
					/>
				</div>
				<div className="flex mx-auto flex-col gap-4 text-center lg:pb-[6rem] lg:pr-4 lg:mx-0">
					<span
						data-aos="zoom-in"
						data-aos-delay="300"
						className="py-3 px-[3rem] bg-secondary rounded-[50px] hover:scale-105 transition-transform w-[250px]"
					>
						<p className="text-white font-semibold">The smiles</p>
					</span>
					<span
						data-aos="zoom-in"
						data-aos-delay="600"
						className="ml-8 py-3 px-[3rem] bg-white rounded-[50px] hover:scale-105 transition-transform w-[250px]"
					>
						<p className="text-black font-semibold">The opportunities</p>
					</span>
					<span
						data-aos="zoom-in"
						data-aos-delay="900"
						className="ml-16 py-3 px-[3rem] bg-secondary rounded-[50px] hover:scale-105 transition-transform w-[250px]"
					>
						<p className="text-white font-semibold">The experiences</p>
					</span>
				</div>
			</div>

			<div className="body-width mt-16 space-y-8">
				<p className="font-semibold text-lg leading-[150%] max-w-[750px]">
					At NADIR, we're on a mission to unlock the potential for positive
					change in every individual. We believe that volunteering, no matter
					how small, can create a ripple effect of impact.
				</p>
				<p className="text-right font-semibold text-lg leading-[150%] max-w-[750px] ml-auto">
					Our platform is designed to connect a global community, breaking down
					barriers to volunteering and making it accessible to all. Join us in
					building a brighter future, where compassion knows no boundaries.
				</p>
			</div>
		</section>
	);
};

export default WhyWeDoWhatWeDo;
