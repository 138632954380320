import React from 'react';
import instagram from '../../../assets/icons/instagram.svg';
import linkedIn from '../../../assets/icons/linkedin.svg';
import tiktok from '../../../assets/icons/tiktok.svg';
import twitter from '../../../assets/icons/twitter.svg';

const OurTeam = () => {
	return (
		<section className="min-h-screen py-8">
			<h2 className="text-4xl font-semibold text-center">Our Team</h2>

			<div className="flex justify-center gap-[4rem] mt-[6rem] max-w-6xl mx-auto flex-wrap mb-8">
				{TEAM_INFO.map((member, index) => (
					<div
						key={member.name}
						className="flex flex-col items-center w-[250px] text-center"
						data-aos="zoom-in"
						data-aos-delay={`${index * 300}`}
					>
						<div className="w-[200px] h-[200px] rounded-full overflow-hidden">
							<img
								alt={member.name}
								src={member.picture}
								className="w-full h-full object-cover"
							/>
						</div>
						<p className="text-lg font-medium">{member.name}</p>
						<p className="text-secondary text-sm font-medium">{member.role}</p>
						<div className="flex gap-2 items-center mt-2">
							{member.socialMedia.instagram && (
								<a
									target="_blank"
									href={member.socialMedia.instagram}
									rel="noreferrer"
								>
									<img
										src={instagram}
										alt="instagram"
										className="w-[20px] h-[20px]"
									/>
								</a>
							)}
							{member.socialMedia.linkedIn && (
								<a
									target="_blank"
									href={member.socialMedia.linkedIn}
									rel="noreferrer"
								>
									<img
										src={linkedIn}
										alt="linkedin"
										className="w-[20px] h-[20px]"
									/>
								</a>
							)}
							{member.socialMedia.tiktok && (
								<a
									target="_blank"
									href={member.socialMedia.tiktok}
									rel="noreferrer"
								>
									<img src={tiktok} alt="tiktok" className="w-[20px] h-[20px]" />
								</a>
							)}
							{member.socialMedia.twitter && (
								<a
									target="_blank"
									href={member.socialMedia.twitter}
									rel="noreferrer"
								>
									<img src={twitter} alt="twitter" className="w-[20px] h-[20px]" />
								</a>
							)}
						</div>
					</div>
				))}
			</div>
		</section>
	);
};

export default OurTeam;

const TEAM_INFO = [
	{
		name: 'Nafisa Umar Abdulrahman',
		role: 'CEO',
		picture: require('../../../assets/images/team/nafisa.png'),
		socialMedia: {
			linkedIn: 'linkedin',
			twitter: 'twitter',
			instagram: 'instagram',
		},
	},
	{
		name: 'Efemena Hilda Onovre',
		role: 'CMO',
		picture: require('../../../assets/images/team/efe.png'),
		socialMedia: {
			linkedIn: 'linkedin',
			twitter: 'twitter',
			instagram: 'instagram',
			tiktok: 'tiktok',
		},
	},
	{
		name: 'Aisha Uthman Muhammad',
		role: 'COO',
		picture: require('../../../assets/images/team/aisha.png'),
		socialMedia: {
			linkedIn: 'linkedin',
			twitter: 'twitter',
			instagram: 'instagram',
		},
	},
	{
		name: 'Yusuf Muhammad Dimari',
		role: 'CTO',
		picture: require('../../../assets/images/team/yusuf.png'),
		socialMedia: {
			linkedIn: 'linkedin',
			twitter: 'twitter',
			instagram: 'instagram',
		},
	},
	{
		name: 'Yahya-Mana Abubakar',
		role: 'Software Engineer',
		picture: require('../../../assets/images/team/abubakar.webp'),
		socialMedia: {
			linkedIn: 'linkedin',
			twitter: 'twitter',
			instagram: 'instagram',
		},
	},
];
