import React from "react";
import "../css/footer.css";
import instagram from "../assets/icons/instagram.svg";
import linkedIn from "../assets/icons/linkedin.svg";
import tiktok from "../assets/icons/tiktok.svg";
import twitter from "../assets/icons/twitter.svg";
import logo1 from "../assets/logo-img.svg";
import logo2 from "../assets/logo-text.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-secondary py-8">
      <div className="body-width flex flex-wrap gap-8 justify-between">
        <div className="">
          <div className="flex items-center gap-2">
            <img className="w-[2rem] h-[2rem]" src={logo1} alt="logo" />
            <img src={logo2} className="h-[0.75rem]" alt="logo" />
          </div>

          <div className="mt-2">
            <h4 className="text-lg font-medium">Address</h4>
            <p className="max-w-[300px] text-sm mt-2 leading-[150%]">
              Plot 681, Cadastral Zone C, OO, Research & Institution Area,
              Airport Road, Jabi 900108, Abuja, Federal Capital Territory
            </p>
          </div>
        </div>

        <ul className="text-sm mt-8 flex flex-col gap-2">
          <li>About us</li>
          <li>Gallery</li>
          <li>Articles</li>
          <Link to="admin/login">Admin</Link>
        </ul>

        <div>
          <div className="mt-6">
            <h4 className="text-lg font-medium">Email</h4>
            <p className="max-w-[300px] text-sm">
              <a
                target="_blank"
                href="mailto:sc.nadir232@gmail.com"
                rel="noreferrer"
              >
                sc.nadir232gmail.com
              </a>
            </p>
          </div>

          <div className="mt-8 flex items-center gap-4">
            <h4 className="text-lg font-medium">Contact Us</h4>
            <div>
              <div className="flex gap-2 items-center">
                <a
                  target="_blank"
                  href={"https://www.instagram.com/nadirovpp"}
                  rel="noreferrer"
                >
                  <img
                    src={instagram}
                    alt="instagram"
                    className="w-[24px] h-[24px]"
                  />
                </a>
                <a
                  target="_blank"
                  href={
                    "https://www.linkedin.com/company/nadir-online-volunteering-platform/"
                  }
                  rel="noreferrer"
                >
                  <img
                    src={linkedIn}
                    alt="linkedin"
                    className="w-[24px] h-[24px]"
                  />
                </a>
                <a
                  target="_blank"
                  href={"https://www.tiktok.com/@nadirovp"}
                  rel="noreferrer"
                >
                  <img
                    src={tiktok}
                    alt="tiktok"
                    className="w-[24px] h-[24px]"
                  />
                </a>
                <a
                  target="_blank"
                  href={"https://x.com/Nadirovpp?s=20"}
                  rel="noreferrer"
                >
                  <img
                    src={twitter}
                    alt="twitter"
                    className="w-[24px] h-[24px]"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
