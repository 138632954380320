import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import Button from "../../components/Button";
import ModalBackdrop from "../../assets/ModalBackdrop";

import { useEvents } from "../../utils";

const Events = () => {
  const { getEvents, selectedEvent, value, onChange, events, rsvpForEvent } =
    useEvents();

  useEffect(() => {
    getEvents();
  }, []);

  const [isVisible, setVisible] = useState(false);
  const toggleVisible = () => {
    setVisible(!isVisible);
  };

  const rsvp = async (e) => {
    e.preventDefault();
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const res = await rsvpForEvent({
      name: name,
      email: email,
      phone: phone,
      eventId: selectedEvent.id,
    });
    if (res) setVisible(false);
  };

  const handleRsvp = (date) => {
    const today = new Date();
    const eventDate = new Date(date);
    if (eventDate >= today) return toggleVisible();
    alert("Event already completed");
  };

  useEffect(() => {
    onChange(value);
  }, [value, events]);

  return (
    <div className="py-10 body-width">
      <h2 className="text-4xl font-semibold text-center" data-aos="zoom-out">
        Events
      </h2>

      <section
        className="mt-12 body-width flex flex-col sm:flex-row gap-8 lg:gap-12"
        data-aos="fade-up"
      >
        <Calendar
          onChange={onChange}
          value={value}
          calendarType="gregory"
          className="text-black flex-1 rounded-lg"
        />

        <article className="flex-1">
          <section
            data-aos="fade-left"
            data-aos-duration="800"
            className="flex flex-col lg:flex-row gap-6 lg:gap-[40px] h-full lg:pl-8"
          >
            {selectedEvent ? (
              <div className="flex-1 space-y-5">
                <div>
                  <h3 className="text-lg font-semibold mb-1">Date</h3>
                  <p>{selectedEvent.date?.toLocaleDateString()}</p>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-1">
                    Event: {selectedEvent.title}
                  </h3>
                  <p>{selectedEvent?.description}</p>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-1">
                    Collaborators and Sponsors
                  </h3>
                  <ul className="list-disc relative left-7">
                    {selectedEvent?.collaborators?.map((collaborator) => (
                      <li>{collaborator}</li>
                    ))}
                  </ul>
                </div>
                <div class="flex justify-center">
                  <Button
                    title="RSVP"
                    onClick={() => handleRsvp(selectedEvent.date)}
                  />
                </div>
              </div>
            ) : (
              <div>
                <h3>No Event on Selected Day</h3>
              </div>
            )}
          </section>
        </article>
      </section>
      <section className="pt-5">
        <h2
          className=" text-2xl sm:text-4xl font-semibold text-center"
          data-aos="zoom-out"
        >
          Upcoming Events
        </h2>
        <ul className="flex flex-col p-5 gap-5">
          {events
            .filter((event) => {
              const today = new Date();
              const eventDate = new Date(event.date);
              return eventDate >= today;
            })
            .map((event) => {
              return (
                <li
                  key={event.eventId}
                  className="flex-1 space-y-5 list-disc p-10 hover:bg-[#59aec2] rounded"
                  onClick={() => onChange(event.date)}
                >
                  <div>
                    <div className="flex gap-5">
                      <h3 className="text-lg font-semibold mb-1">
                        Event: {event.title}
                      </h3>
                      <p>{`(${event.date?.toLocaleDateString()})`}</p>
                    </div>
                    <p>{event?.description}</p>
                  </div>
                </li>
              );
            })}
        </ul>
      </section>
      <div>
        {isVisible && (
          <ModalBackdrop>
            <div class="flex flex-col gap-10 bg-[#243443] rounded  py-20 px-10 sm:py-24 sm:px-10">
              <div class="flex gap-2 place-items-center">
                <h2 class="min-w-20">Full Name:</h2>
                <input
                  id="name"
                  class="border-white rounded h-10 bg-transparent border-2 p-10 sm:min-w-96 "
                />
              </div>
              <div class="flex gap-2 place-items-center">
                <h2 class="min-w-20">Email:</h2>
                <input
                  type="email"
                  id="email"
                  class="border-white rounded h-10 bg-transparent border-2 p-10 sm:min-w-96"
                />
              </div>
              <div class="flex gap-2 place-items-center">
                <h2 class="min-w-20">Phone:</h2>
                <input
                  type="number"
                  id="phone"
                  class="border-white rounded h-10 bg-transparent border-2 p-10 min-w-9 sm:min-w-96"
                />
              </div>
              <div class="flex justify-center">
                <Button title="RSVP" onClick={rsvp} />
              </div>
            </div>
          </ModalBackdrop>
        )}
      </div>
    </div>
  );
};

export default Events;
