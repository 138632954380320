import React, { useState } from "react";
import { FilePicker } from "../../components/FilePicker";
import { useAdmin } from "../../utils/hooks/Admin/useAdmin";

export const AddEvent = () => {
  const [img, setImg] = useState(null);
  const { addEvent, isLoading } = useAdmin();
  const handleSubmit = (e) => {
    e.preventDefault();
    const title = document.getElementById("title").value;
    const date = document.getElementById("date").value;
    const description = document.getElementById("description").value;
    addEvent({
      title: title,
      description: description,
      date: new Date(date),
      img: img,
    });
  };

  return (
    <div class="body-width flex flex-col gap-10 py-10">
      <div class="flex flex-col sm:flex-row align-center gap-10 p-10 ">
        <div class="w-[15Rem] h-[15Rem] sm:w-[30Rem] sm:h-[30Rem] flex justify-center place-items-center">
          <FilePicker setFile={setImg} img={img} />
        </div>
        <form
          class="flex flex-col w-[100%] max-w-sm mx-auto"
          onSubmit={handleSubmit}
        >
          <div class="mb-5">
            <label
              for="title"
              class="block mb-2 text-sm font-medium dark:text-white"
            >
              Event Name
            </label>
            <input
              type="text"
              id="title"
              class="bg-gray-50 border border-gray-300  text-sm rounded-lg text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Event Date"
              required
            />
          </div>
          <div class="mb-5">
            <label
              for="date"
              class="block w-[100%] mb-2 text-sm font-medium  dark:text-white"
            >
              Event Date
            </label>
            <input
              type="datetime-local"
              id="date"
              class="bg-gray-50 border border-gray-300 text-gray-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@flowbite.com"
              required
            />
          </div>
          <div class="mb-5">
            <label
              for="description"
              class="block mb-2 text-sm font-medium  dark:text-white"
            >
              Event Description
            </label>
            <textarea
              required
              type="text"
              id="description"
              class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          {/* Collaborators */}

          <button
            type="submit"
            class="text-white bg-secondary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-secondary dark:hover:bg-secondary dark:focus:ring-blue-800"
            disabled={isLoading}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
