import React from "react";
import Button from "../../components/Button";
import logo1 from "../../assets/logo-img.svg";
import logo2 from "../../assets/logo-text.svg";
import OurTeam from "./components/OurTeam";
import BrandMeaning from "./components/BrandMeaning";
import OurPlatform from "./components/OurPlatform";
import WhyWeDoWhatWeDo from "./components/WhyWeDoWhatWeDo";

const AboutUs = () => {
  return (
    <>
      <div
        className="py-10 relative flex flex-col body-width"
        style={{ minHeight: "calc(100vh - 90px)" }}
      >
        <h2
          className="absolute w-full text-2xl lg:text-4xl font-semibold text-center"
          data-aos="zoom-out"
          data-aos-duration="1000"
        >
          About Us
        </h2>

        <section className="flex-1 flex flex-col justify-center lg:flex-row lg:items-center h-full gap-[40px]">
          <div
            className="lg:flex-1"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="text-2xl lg:text-4xl font-medium mb-6 leading-[120%]">
              Nadir:
              <br />
              An Online Volunteering Platform
            </h3>
            <a
              href="https://chat.whatsapp.com/CQnO1kK5iyRA9u85E7eJZq"
              target="_blank"
            >
              <Button title="Join us" primary={false} />
            </a>
          </div>

          <div
            data-aos="zoom-in"
            className="absolute top-[50%] right-0 w-[60%] rounded-l-lg shadow-inner shadow-secondary -translate-y-[50%] lg:shadow-none lg:-translate-y-0 lg:flex-1 lg:static"
          >
            <div className="flex opacity-10 lg:opacity-100 flex-col items-center gap-4 shadow-2xl p-8 max-w-[500px] mx-auto">
              <img className="w-[10rem] h-[10rem]" src={logo1} alt="logo" />
              <img className="h-[2rem]" src={logo2} alt="logo" />
            </div>
          </div>
        </section>
      </div>

      <BrandMeaning />
      <WhyWeDoWhatWeDo />
      <OurPlatform />
      <OurTeam />
    </>
  );
};

export default AboutUs;
