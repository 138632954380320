export const routes = Object.freeze({
  base: "/",
  about: "/about-us",
  events: "events",
  joinUs: "/join-us",
  gallery: "/gallery",
  articles: "/articles",
  contactUs: "/contact-us",
  admin: {
    login: "/admin/login",
    dashboard: "/admin/dashboard",
    addEvent: "/admin/addEvent",
    addTestimony: "/admin/addTestimony",
  },
});
