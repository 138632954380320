import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../utils/providers/AppProvider";

export default function ProtectedRoute({ children }) {
  const { user } = useAppContext();
  const location = useLocation();
  if (!user?.isAdmin) {
    return <Navigate to={"/admin/login"} state={{ from: location }} />;
  } else {
    return children;
  }
}
